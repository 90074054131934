import { Avatar, Typography } from "antd"
import React, { useEffect, useState } from "react"
import { getProductId } from "../../../../../services/apiProduct"
import InfoProduct from "../InfoProduct"
import MessageContent from "./MessageContent"

const { Text } = Typography

const ChatContainer = ({
  avatar,
  name,
  nameBuyer,
  content,
  time,
  itemStore,
}) => {
  return (
    <div className="flex items-start mb-5">
      <div>
        <Avatar
          style={{
            backgroundColor: "#FA541C",
            verticalAlign: "middle",
          }}
          size="large"
        >
          {avatar}
        </Avatar>
      </div>
      <div className="ml-[10px]">
        <Text className="!text-[#343434] text-[13px] font-bold">{name}</Text>
        <div className="!text-[#343434] text-[13px] font-bold">{nameBuyer}</div>
        <div className="mt-3">
          <div>
            <MessageContent content={content} time={time} />
            {itemStore && <InfoProduct infoProduct={itemStore} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatContainer
