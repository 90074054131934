import { Breadcrumb, Spin, Typography } from "antd"
import React, { useState } from "react"
import Chat from "./chat"

import { createContext } from "react"
import TableOrder from "./TableOrder"
import { Link } from "gatsby"

export const DetailOrderContext = createContext()

const DetailContent = ({ obj }) => {
  const [shippingCompany, setShippingCompany] = useState("")
  const [shippingNumber, setShippingNumber] = useState("")
  const [loading, setLoading] = useState(false)
  const [inboxIdentifier, setInboxIdentifier] = useState()
  const [deliveryDate, setDeliveryDate] = useState({
    dateType: undefined,
    stringType: "",
  })
  const [desiredDateArrival, setDesiredDateArrival] = useState({
    dateType: undefined,
    stringType: "",
  })

  const [desiredTimeArrival, setDesiredTimeArrival] = useState({
    timeType: undefined,
    stringType: "",
  })

  const [shippingTrackingURL, setSetShippingTrackingURL] = useState("")
  const [detailOrderData, setDetailOrderData] = useState({})
  const [dataOrder, setDataOrder] = useState([])
  const [nameBuyer, setNameBuyer] = useState("")

  const detailOrderContextValue = {
    obj,
    orderId: obj.order_id,
    setInboxIdentifier,
    dataOrder,
    setDataOrder,
    shippingCompany,
    setShippingCompany,
    deliveryDate,
    setDeliveryDate,
    shippingNumber,
    setShippingNumber,
    desiredDateArrival,
    setDesiredDateArrival,
    desiredTimeArrival,
    setDesiredTimeArrival,
    shippingTrackingURL,
    setSetShippingTrackingURL,
    detailOrderData,
    setDetailOrderData,
    nameBuyer,
    setNameBuyer,
    setLoading,
  }

  return (
    <DetailOrderContext.Provider value={detailOrderContextValue}>
      <Spin spinning={loading}>
        <div className="">
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>お問合せ管理</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Typography.Title className="!text-2xl !font-medium !text-black-5 !mt-9">
          商品問い合わせ詳細
        </Typography.Title>
        {/* <TableDetail
          dataInfo={dataInfo}
          productInfo={productInfo}
          dataPaymentInfo={dataPaymentInfo}
          dataSoldOutCancelReturn={dataSoldOutCancelReturn}
        /> */}
        <TableOrder />
        <Chat obj={obj} />
      </Spin>
    </DetailOrderContext.Provider>
  )
}

export default DetailContent
