import React, { memo, useCallback, useContext, useState } from "react"
import { DetailOrderContext } from "."
import { onKeyPressNumber } from "../../../customUtil/global"
import { productNegotiate } from "../../../services/apiProduct"

const ChangeQuanityStep = (props) => {
  const { setDataOrder } = useContext(DetailOrderContext)
  const [count, setCount] = useState(props.amount ?? 0)

  const updateAmount = useCallback(
    async (num) => {
      props.setLoading(true)
      const { response, data } = await productNegotiate({
        prices: [
          {
            variant_id: props.idVariant,
            max_quantity: num,
          },
        ],
        customer_id: props.cusId,
      })
      if (response.status == 200) {
        // console.log(data)
        const { variants } = data
        const newData = variants.map((item, i) => {
          // props.setEditPrice((prev) => ({
          //   ...prev,
          //   [i]: 100,
          // }))
          return {
            key: item.id,
            price_list_id:
              item.prices?.filter(
                (price) => price.price_type === "negotiate"
              )[0]?.price_list_id ?? "",
            record: {
              ...item,
              thumbnail: item.product?.images2?.image?.url,
              amount: item.productNegotiate[0].amount,
            },
          }
        })
        setDataOrder(newData)
        props.setLoading(false)
      } else {
        console.log(response?.statusText)
        props.setLoading(false)
      }
    },
    [count]
  )

  const handleOnUpCount = () => {
    if (count < props.inventoryQuantity) {
      props.setToggleError((prev) => ({
        ...prev,
        [props.index]: false,
      }))
      setCount((prev) => prev + 1)
      updateAmount(count + 1)
    } else {
      props.setToggleError((prev) => ({
        ...prev,
        [props.index]: true,
      }))
    }
  }

  const handleOnDownCount = () => {
    if (count === 1) {
      props.setPriceListId(
        props.data.price_list_id ? [props.data.price_list_id] : []
      )
      props.setVariantId(props.data.record.id)
      props.setModal2Visible(true)
    }
    if (count > 1) {
      props.setToggleError((prev) => ({
        ...prev,
        [props.index]: !(count - 1 <= props.inventoryQuantity),
      }))
      setCount((prev) => prev - 1)
      updateAmount(count - 1)
    }
  }

  return (
    <div className={`flex ${props.height}`}>
      <button
        className={`w-[22px] text-xl ${props.height} 
       border hover:border-red-500 hover:text-primary-90
       ${count ? "bg-pink-1 text-primary-90" : "text-[#c4c4c4] bg-[#F0F0F0]"}`}
        onClick={handleOnDownCount}
      >
        -
      </button>
      <input
        className={`w-[56px] outline-none text-center ${
          count ? "text-primary-90" : ""
        }`}
        value={count}
        onKeyPress={onKeyPressNumber}
        onBlur={(e) => {
          if (e.target.value) {
            updateAmount(Number(e.target.value))
          }
        }}
        onChange={(e) => {
          const newNumber = e.target.value
          if (!isNaN(+newNumber)) {
            if (+newNumber <= props.inventoryQuantity) {
              props.setToggleError((prev) => ({
                ...prev,
                [props.index]: false,
              }))
              setCount(+newNumber)
              return
            } else {
              props.setToggleError((prev) => ({
                ...prev,
                [props.index]: true,
              }))
              return
            }
          }
        }}
      />
      <button
        className={`w-[22px] text-xl ${props.height}
      border hover:border-primary-90 hover:text-primary-90
      ${count ? "bg-pink-1 text-primary-90" : "text-[#c4c4c4] bg-[#F0F0F0]"}`}
        onClick={handleOnUpCount}
      >
        +
      </button>
    </div>
  )
}

export default memo(ChangeQuanityStep)
