import React from "react"
import { Divider } from "antd"

const HeaderChat = ({ title }) => {
  return (
    <div>
      <h3 className="text-primary-90 font-bold text-2xl">{title}</h3>
      <Divider className="!bg-primary-90 !mt-[14px]" />
    </div>
  )
}

export default HeaderChat
