import { Divider, Typography } from "antd"
import React from "react"
import InfoVariant from "./InfoVariant"
import NoImg from "../../../../images/no_image.jpg"

const { Title } = Typography

const InfoProduct = ({ infoProduct }) => {
  return (
    <div className="w-[625px] bg-[#fafafa] py-2 mt-3">
      <Title level={3} className="!text-xl ml-6 mt-6">
        {infoProduct?.title}
      </Title>
      <Divider />
      <div>
        {infoProduct?.variants?.map((item, index) => (
          <InfoVariant
            key={index}
            record={item}
            thumbnail={
              infoProduct?.images2?.filter((el) => el.type == "THUMBNAIL")?.[0]
                ?.image?.url || NoImg
            }
          />
        ))}
      </div>
    </div>
  )
}

export default InfoProduct
