import moment from "moment"

export const apiAddress = (zipCode) =>
  `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zipCode}`

export const japaneseDateConvert = (string) => {
  const date = moment(string)
  return date.format("YYYY/MM/DD HH:mm")
}

export const getDate = (date = 0) => {
  return new Date(Date.now() - date * 24 * 60 * 60 * 1000).toISOString()
}

export const orderStatusMap = {
  pending: {
    display: "確認待ち",
    value: "pending",
  },
  toShip: {
    display: "発送完了",
    value: "toShip",
  },
  prepare: {
    display: "発送準備中",
    value: "prepare",
  },

  completed: {
    display: "発注処理完了",
    value: "completed",
  },
  canceled: {
    display: "キャンセル",
    value: "canceled",
  },
  requires_action: {
    display: "返品・返金処理中",
    value: "requires_action",
  },
  refunded: {
    display: "返品・返金処理完了",
    value: "refunded",
  },
}

export const fulfillmentStatusMap = {
  not_fulfilled: {
    value: "not_fulfilled",
    display: "満たされていない",
    keyTab: "3",
  },
  partially_fulfilled: {
    value: "partially_fulfilled",
    display: "部分的に満たされた",
    keyTab: "3",
  },
  fulfilled: {
    value: "fulfilled",
    display: "満たされた",
    keyTab: "7",
  },
  partially_shipped: {
    value: "partially_shipped",
    display: "一部出荷",
    keyTab: "4",
  },
  shipped: {
    value: "shipped",
    display: "出荷された",
    keyTab: "4",
  },
  partially_returned: {
    value: "partially_returned",
    display: "一部返却",
    keyTab: "6",
  },
  returned: {
    value: "returned",
    display: "戻ってきた",
    keyTab: "6",
  },
  canceled: {
    value: "canceled",
    display: "キャンセル処理",
    keyTab: "5",
  },
  requires_action: {
    value: "requires_action",
    display: "アクションが必要です",
    keyTab: "2",
  },
}

export const getShippingAddress = (order) => {
  const shippingAddress = order?.shipping_address || {}
  const address_1 = shippingAddress?.address_1 || ""
  const address_2 = shippingAddress?.address_2 || ""
  const city = shippingAddress?.city || ""
  const province = shippingAddress?.province
  const postalCode = shippingAddress.postal_code

  return `${postalCode} ${province} ${city} ${address_1} ${address_2}`
}

export const formatter = (value) =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
export const parser = (value) => value.replace(/\$\s?|(,*)/g, "")

export const onKeyPressNumber = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault()
  }
}

export const getApplication = (
  user,
  applicationFeeRetail,
  applicaitonFeeWholesale
) => {
  if (user?.user_type === "BUYER") {
    return applicationFeeRetail / 100
  }
  return applicaitonFeeWholesale / 100
}

export const formatNumberJP = (number) =>
  new Intl.NumberFormat("ja-JP", {
    maximumFractionDigits: 0,
  }).format(number)

export const priceAfterFloorFee = (
  price,
  user,
  applicationFeeRetail,
  applicaitonFeeWholesale
) => {
  const application = getApplication(
    user,
    applicationFeeRetail,
    applicaitonFeeWholesale
  )
  const newPrice = price + price * application
  return formatNumberJP(newPrice)
}

export const customTableProduct = (item) => {
  const wholesalePrice = item?.prices.filter(
    (price) => price.price_type === "wholesale"
  )
  const sellingPrice = item?.prices.filter(
    (price) => price.price_type === "retail"
  )
  return {
    ...item,
    is_displayed: {
      is_displayed: item.is_displayed,
      id: item.id,
      status: item?.product?.status,
    },
    thumbnail: item?.product?.thumbnail,
    title: item?.product?.title,
    key: item?.id,
    sales_standard:
      item?.weight === 0
        ? "最小ロット"
        : item?.weight === 1
        ? "中ロット"
        : "大ロット",
    JANcode: item?.hs_code ? item?.hs_code : "",
    created_at: item?.created_at
      ? moment(item?.created_at).format("YYYY-MM-DD")
      : "",
    updated_at: item?.updated_at
      ? moment(item?.updated_at).format("YYYY-MM-DD")
      : "",
    status:
      item?.product?.status === "published"
        ? item?.is_displayed
          ? "出品中"
          : "出品停止"
        : item?.product?.status === "draft"
        ? "下書き"
        : "申請中",
    price_amount: item.price ? item?.price_amount : "",
    min_price: item.prices ? formatNumberJP(item?.prices[0]?.min_price) : "",
    wholesale_price: item?.product?.is_constant
      ? wholesalePrice?.length > 0 &&
        `${formatNumberJP(wholesalePrice[0]?.min_price)}円/ ${formatNumberJP(
          wholesalePrice[0]?.amount_unit
        )}${wholesalePrice[0]?.unit || ""}`
      : wholesalePrice?.length > 0 &&
        `${formatNumberJP(wholesalePrice[0]?.min_price)}円～${formatNumberJP(
          wholesalePrice[0]?.max_price
        )}円/ ${formatNumberJP(wholesalePrice[0]?.amount_unit)}${
          wholesalePrice[0]?.unit || ""
        }`,
    selling_price: item?.product?.is_constant
      ? sellingPrice?.length > 0 &&
        `${formatNumberJP(sellingPrice[0]?.min_price)}円/ ${formatNumberJP(
          sellingPrice[0]?.amount_unit
        )}${sellingPrice[0]?.unit || ""}`
      : sellingPrice?.length > 0 &&
        `${formatNumberJP(sellingPrice[0]?.min_price)}円 ～${formatNumberJP(
          sellingPrice[0]?.max_price
        )}円/ ${formatNumberJP(sellingPrice[0]?.amount_unit)}${
          sellingPrice[0]?.unit || ""
        }`,
    inventory_quantity: formatNumberJP(item?.inventory_quantity),
    category_small: item?.product?.collection
      ? item?.product?.collection?.title
      : "",
    category_middle: item?.product?.collectionMedium
      ? item?.product?.collectionMedium?.title
      : "",
    category_large: item?.product?.collectionMax
      ? item?.product?.collectionMax?.title
      : "",
  }
}
export function beginningOfMonth(myDate) {
  const date = new Date(myDate)
  date.setDate(1)
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  return date
}

export function endOfMonth(myDate) {
  const date = new Date(myDate)
  date.setDate(1) // Avoids edge cases on the 31st day of some months
  date.setMonth(date.getMonth() + 1)
  date.setDate(0)
  date.setHours(23)
  date.setMinutes(59)
  date.setSeconds(59)
  return date
}
