import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  Divider,
  Space,
  Table,
  Typography,
  Row,
  Col,
  Button,
  Input,
  Modal,
} from "antd"
import ChangeQuanityStep from "./ChangeQuanityStep"
import { navigate } from "gatsby"
import { DetailOrderContext } from "."
import {
  productListNegotiate,
  productNegotiate,
} from "../../../services/apiProduct"
import { editPriceVariant } from "../../../services/order"
import {
  deleteNegotiate,
  getCustomerid,
  getStoreById,
  publicProduct,
  sendMessage,
} from "../../../services/user"
import { AccountContext } from "../../../context/account"
import { formatNumberJP, onKeyPressNumber } from "../../../customUtil/global"
const { Text } = Typography
import NoImg from "../../../images/no_image.jpg"

const TableOrder = () => {
  const {
    orderId,
    dataOrder,
    setDataOrder,
    obj,
    setNameBuyer,
    setInboxIdentifier,
  } = useContext(DetailOrderContext)
  const { user } = useContext(AccountContext)
  const [loading, setLoading] = useState(false)
  const [editPrice, setEditPrice] = useState({})
  const [toggleError, setToggleError] = useState({})
  const [cusId, setCusId] = useState()
  const [modal2Visible, setModal2Visible] = useState(false)
  const [variantId, setVariantId] = useState("")
  const [priceListId, setPriceListId] = useState([])
  const [priceSum, setPriceSum] = useState({
    sum: 0,
    sumApplication: 0,
    taxFee: 0,
    total: 0,
  })

  const disabledBtn = useMemo(() => {
    for (const element in editPrice) {
      if (editPrice[element] <= 0) {
        return true
      }
    }
    return false
  }, [editPrice])

  const handleUpdatePrice = async (e, { record, price_list_id, id }) => {
    setLoading(true)
    if (price_list_id) {
      await productNegotiate({
        prices: [
          {
            id: id,
            amount: e.target.value,
            variant_id: record.id,
            currency_code: "jpy",
            price_type: "negotiate",
            max_quantity: record.amount,
            price_list_id: price_list_id,
          },
        ],
        customer_id: cusId,
      })
      setLoading(false)
    } else {
      const { response, data } = await editPriceVariant({
        prices: [
          {
            amount: e.target.value,
            variant_id: record.id,
            currency_code: "jpy",
            price_type: "negotiate",
            max_quantity: record.amount,
          },
        ],
        customer_id: cusId,
      })
      if (response.status == 200) {
        const { variants } = data
        const newData = variants.map((item, i) => {
          setEditPrice((prev) => ({
            ...prev,
            [i]:
              item.prices?.filter(
                (price) => price.price_type === "negotiate"
              )[0]?.amount ?? 0,
          }))
          return {
            key: item.id,
            id:
              item.prices?.filter(
                (price) => price.price_type === "negotiate"
              )[0]?.id ?? "",
            price_list_id:
              item.prices?.filter(
                (price) => price.price_type === "negotiate"
              )[0]?.price_list_id ?? "",
            record: {
              ...item,
              thumbnail: item.product.thumbnail,
              amount: item.productNegotiate[0].amount,
            },
          }
        })
        setDataOrder(newData)
        setLoading(false)
      } else {
        console.log(response?.statusText)
        setLoading(false)
      }
    }
  }

  console.log("dataOrder", dataOrder)

  const fee = useMemo(() => {
    const data = dataOrder?.map((order) => ({
      applicationFee: order?.record?.product?.applicationFee / 100,
    }))
    const dataTaxFee = dataOrder?.map((order) => ({
      taxFee: order?.record?.product?.taxFee / 100,
    }))
    return {
      applicationFee: data,
      taxFee: dataTaxFee,
    }
  }, [dataOrder])

  // console.log(fee)
  console.log("fee", fee)

  const cusTomData = (data) => {
    const { variants } = data
    const newData = variants.map((item, i) => {
      setEditPrice((prev) => ({
        ...prev,
        [i]:
          item.prices?.filter((price) => price.price_type === "negotiate")[0]
            ?.amount ?? 0,
      }))
      setToggleError((prev) => ({
        ...prev,
        [i]: false,
      }))
      return {
        key: item.id,
        id:
          item.prices?.filter((price) => price.price_type === "negotiate")[0]
            ?.id ?? "",
        price_list_id:
          item.prices?.filter((price) => price.price_type === "negotiate")[0]
            ?.price_list_id ?? "",
        record: {
          ...item,
          thumbnail: item.product?.images2?.image?.url,
          amount: item.productNegotiate[0].amount,
        },
      }
    })
    setDataOrder(newData)
    setLoading(false)
  }

  const columns = [
    {
      title: (
        <div
          className="title_table"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {"商品名"}
        </div>
      ),
      width: "497px",
      dataIndex: "record",
      render: (record) => (
        <div className="flex">
          <img
            style={{ width: "60px", height: "35px", objectFit: "cover" }}
            alt={record.thumbnail}
            src={record.thumbnail || NoImg}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              currentTarget.src = NoImg
            }}
          />

          <Text className="cart_product_column_name">{record.title}</Text>
        </div>
      ),
    },
    {
      title: <div className="title_table">数量</div>,
      width: "434px",
      align: "center",
      dataIndex: "record",
      render: (record, data, index) => {
        record.prices = record.prices?.filter(
          (price) => price.price_type !== "negotiate"
        )
        return (
          <>
            <Space className="cart_product_column_option">
              {record?.product?.is_constant ? (
                <div className="cart_product_column_optionNumber">
                  <Text
                    style={{
                      fontWeight: 700,
                      fontSize: "14px",
                      color: "#222222",
                    }}
                    className="line-clamp-1"
                  >
                    {record?.prices[0]
                      ? formatNumberJP(record?.priceCalculation?.min_price)
                      : "-"}
                    円 /バラ{" "}
                    {record?.prices[0]
                      ? formatNumberJP(record?.prices[0]?.amount_unit)
                      : "-"}{" "}
                    {record?.prices[0] ? record?.prices[0].unit : "-"} 入り
                  </Text>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  <Text
                    style={{
                      display: "block",
                      fontSize: "12px",
                      color: "#595959",
                    }}
                    className="line-clamp-1"
                  >
                    <Text
                      style={{
                        fontWeight: 700,
                        fontSize: "12px",
                        color: "#222222",
                      }}
                    >
                      {record?.prices[0]
                        ? formatNumberJP(record?.priceCalculation?.m_price)
                        : "-"}
                    </Text>
                    円 /バラ{" "}
                    <Text style={{ fontWeight: 700, color: "#595959" }}>
                      {record?.prices[0]
                        ? record?.prices[0].m_amount_unit
                        : "-"}{" "}
                    </Text>
                    個 あたり
                  </Text>
                  <Text
                    style={{ fontSize: "12px", color: "#595959" }}
                    className="line-clamp-1"
                  >
                    <Text style={{ fontWeight: 700, color: "#595959" }}>
                      {record?.prices[0]
                        ? formatNumberJP(record?.prices[0].price_per_one)
                        : "-"}
                    </Text>
                    円 /{" "}
                    {record?.prices[0]
                      ? record?.priceCalculation?.another_unit
                      : "-"}{" "}
                    あたり
                  </Text>
                </div>
              ) : (
                <div className="cart_product_column_optionNumber">
                  <Text
                    style={{
                      fontWeight: 700,
                      fontSize: "14px",
                      color: "#222222",
                    }}
                    className="line-clamp-1"
                  >
                    {`${formatNumberJP(record?.priceCalculation?.min_price)}
                    ～ ${formatNumberJP(record?.priceCalculation?.max_price)}`}
                    円 /バラ{" "}
                    {record?.prices[0]
                      ? formatNumberJP(record?.prices[0]?.amount_unit)
                      : "-"}{" "}
                    {record?.prices[0] ? record?.prices[0].unit : "-"} 入り
                  </Text>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  <Text
                    style={{
                      display: "block",
                      fontSize: "12px",
                      color: "#595959",
                    }}
                    className="line-clamp-1"
                  >
                    <Text
                      style={{
                        fontWeight: 700,
                        fontSize: "12px",
                        color: "#222222",
                      }}
                    >
                      {record?.prices[0]
                        ? formatNumberJP(
                            record?.priceCalculation?.price_content_unit
                          )
                        : "-"}
                    </Text>
                    円 /バラ{" "}
                    <Text style={{ fontWeight: 700, color: "#595959" }}>
                      {record?.prices[0]
                        ? record?.prices[0]?.another_unit
                        : "-"}{" "}
                    </Text>
                    個 あたり
                  </Text>
                </div>
              )}
              <ChangeQuanityStep
                height="h-[86px]"
                amount={record.amount}
                data={data}
                inventoryQuantity={record.inventory_quantity}
                idVariant={record.id}
                index={index}
                setToggleError={setToggleError}
                setEditPrice={setEditPrice}
                setLoading={setLoading}
                setPriceListId={setPriceListId}
                setVariantId={setVariantId}
                setModal2Visible={setModal2Visible}
                cusId={cusId}
              />
            </Space>

            {toggleError[index] && (
              <div className="flex justify-center text-custom-1 text-xs">
                選択した数量がこの商品の最大数に達しました (
                {record.inventory_quantity} 商品まで)
              </div>
            )}
          </>
        )
      },
    },
    {
      title: <div className="title_table">商品代金</div>,
      width: "175px",
      align: "center",
      dataIndex: "record",
      render: (record, data, index) => {
        return (
          <Input
            onKeyPress={onKeyPressNumber}
            value={editPrice[index]}
            onBlur={(e) => {
              if (Number(e.target.value) > 0) {
                handleUpdatePrice(e, data)
              }
            }}
            onChange={(e) => {
              const newNumber = e.target.value
              if (!isNaN(+newNumber)) {
                setEditPrice((prev) => ({
                  ...prev,
                  [index]: +newNumber,
                }))
                return
              }
            }}
            addonAfter="円"
          />
        )
      },
    },
    {
      title: <div className="title_table">手数料</div>,
      width: "130px",
      align: "center",
      dataIndex: ["record", "product", "applicationFee"],
      render: (applicationFee, _, index) => {
        return (
          <div>
            {formatNumberJP((editPrice[index] * applicationFee) / 100)}円
          </div>
        )
      },
    },
    {
      title: <div className="title_table">消費税</div>,
      width: "130px",
      align: "center",
      dataIndex: ["record", "product", "taxFee"],
      render: (taxFee, _, index) => {
        return <div>{formatNumberJP((editPrice[index] * taxFee) / 100)}円</div>
      },
    },
    {
      title: <div className="title_table">販売価格</div>,
      width: "130px",
      align: "center",
      dataIndex: ["record", "product"],
      render: (product, _, index) => {
        return (
          <div>
            {formatNumberJP(
              editPrice[index] *
                (1 + product?.applicationFee / 100 + product?.taxFee / 100)
            )}
            円
          </div>
        )
      },
    },
    {
      title: <div className="title_table">操作</div>,
      width: "130px",
      align: "center",
      dataIndex: "record",
      render: (record, data) => {
        return (
          <Button
            danger
            onClick={async () => {
              setPriceListId(data.price_list_id ? [data.price_list_id] : [])
              setVariantId(record.id)
              setModal2Visible(true)
            }}
          >
            削除
          </Button>
        )
      },
    },
  ]

  useEffect(() => {
    if (fee) {
      let sum = 0
      let sumApplication = 0
      let taxFee = 0

      for (let i = 0; i < dataOrder?.length; i++) {
        sum += editPrice[i]
        sumApplication +=
          editPrice[i] * fee?.applicationFee?.[i]?.applicationFee
        taxFee += editPrice[i] * fee?.taxFee?.[i]?.taxFee
      }
      const total = sum + sumApplication + taxFee
      setPriceSum({ sum, sumApplication, taxFee, total })
    }
  }, [fee, editPrice])

  useEffect(() => {
    if (orderId) {
      ;(async function () {
        setLoading(true)
        getCustomerid(orderId)
          .then(({ data }) => {
            if (data.customer_id) {
              setCusId(data.customer_id)
              setInboxIdentifier(data.buyer_inboxIdentifier)
              getStoreById(data.buyer_id)
                .then((res) => {
                  setNameBuyer(res.data.stores.name)
                })
                .catch((err) => console.log(err))
              productListNegotiate({
                customerId: data.customer_id,
                variantIds: [],
              })
                .then(({ data }) => {
                  cusTomData(data)
                })
                .catch((err) => {
                  console.log(err)
                  setLoading(false)
                })
            }
          })
          .catch((err) => console.log(err))
      })()
    }
  }, [orderId])

  const hanlePublic = () => {
    console.log("dataOrderdataOrderdataOrder", dataOrder)
    setLoading(true)
    const code = Date.now()
    const dataConfirm = dataOrder.map(
      ({ record, price_list_id, id }, index) => ({
        id: id,
        amount: editPrice[index],
        variant_id: record.id,
        currency_code: "jpy",
        price_type: "negotiate",
        max_quantity: record.amount,
        price_list_id: price_list_id,
      })
    )
    console.log("dataConfirmdataConfirmdataConfirm", dataConfirm)
    const dataVariants = dataOrder.map((el, index) => {
      return { ...el, priceNegotiate: editPrice[index] }
    })
    console.log("dataVariantsdataVariants", dataVariants)

    publicProduct({
      prices: dataConfirm,
      customer_id: cusId,
      code,
    })
      .then(() => {
        sendMessage(
          {
            // haveProduct: 0,
          },
          {
            content: "Done",
            message_type: "outgoing",
            // contact_identifier: obj.contact_identifier,
            conversation_id: String(obj.order_id),
            account_id: obj.account_id,
            content_type: "text",
            content_attributes: {
              items: [
                {
                  variants: dataVariants,
                },
              ],
            },
            store_seller_id: user.store_id,
          }
        )
          .then(() => {
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth",
            })
            const myElement = document.getElementById("scrollableDiv")
            myElement.scrollTo({
              top: myElement.scrollHeight,
              behavior: "smooth",
            })
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
          })
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  console.log("editPrice", editPrice)

  return (
    <div className="tableOrder mt-[20px]">
      <Table
        locale={{ emptyText: "データなし" }}
        scroll={{ x: 1300 }}
        loading={loading}
        className="tableMe border-[1px]"
        columns={columns}
        dataSource={dataOrder}
        pagination={false}
      />
      <div style={{ minWidth: 620 }}>
        <Row gutter={20}>
          <Col offset={16} span={8}>
            <div className="flex flex-col text-base mt-[52px]">
              <div className="mb-[24px] text-right">
                <Button
                  type="danger"
                  onClick={() =>
                    navigate("/inquiry-management/setting-management", {
                      state: { id: orderId, customer_id: cusId },
                    })
                  }
                >
                  ＋ 商品を追加する
                </Button>
              </div>
              <div className="flex justify-between items-center text-black-0">
                <span>商品小計</span>
                <span>{formatNumberJP(priceSum?.sum)}円</span>
              </div>
              <div className="flex justify-between items-center text-black-0">
                <span>手数料</span>
                <span>{formatNumberJP(priceSum?.sumApplication)}円</span>
              </div>
              <div className="flex justify-between items-center text-black-0">
                <span>消費税</span>
                <span>{formatNumberJP(priceSum?.taxFee)}円</span>
              </div>
              <Divider />
              <div className="flex justify-between items-center">
                <span className="text-custom-34">合計</span>
                <span className="text-primary-90 text-xl">
                  {formatNumberJP(priceSum.total)}円
                </span>
              </div>
              <div className="flex flex-col mt-[31px]">
                <Button
                  danger
                  style={{
                    height: 39,
                  }}
                >
                  見積書ダウンロード
                </Button>
                <Button
                  className="mt-[11px]"
                  style={{
                    height: 39,
                  }}
                  type="primary"
                  disabled={dataOrder.length == 0 ? true : disabledBtn}
                  onClick={hanlePublic}
                  loading={loading}
                >
                  この見積りで購入に進む
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          title="この商品を削 除してもよろしいでしょうか？"
          okText="削除"
          cancelText="キャンセル"
          centered
          visible={modal2Visible}
          onOk={async () => {
            setLoading(true)
            const { data } = await deleteNegotiate({
              customerId: cusId,
              priceIds: priceListId,
              ids: [variantId],
            })
            if (data) {
              cusTomData(data)
            }
            setModal2Visible(false)
          }}
          onCancel={() => {
            setModal2Visible(false)
            setVariantId("")
          }}
        >
          <p>
            このアクションを実施すると受注に関する商品に影響を与える可能性があります。
          </p>
        </Modal>
      </div>
    </div>
  )
}

export default TableOrder
